<template>
  <FullScreenDialog
    v-model="invoiceDialog"
    title="Nuova Nota a Credito"
    :routeFather="pathToGoBack"
  >
    <template v-slot:toolbar-extension>
      <v-tabs v-model="tab" align-with-title>
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab v-for="tab in invoiceCreditNoteTabs" :key="tab.key">{{ tab.title }}</v-tab>
      </v-tabs>
    </template>
    <template v-slot:content>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="tabItem in invoiceCreditNoteTabs" :key="tabItem.key">
          <v-card flat>
            <component :bus="invoiceBus" :is="tabItem.component"></component>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>
    <template v-slot:footer-actions>
      <v-spacer></v-spacer>
      <v-btn text color="error" @click="$router.push({ path: pathToGoBack })">
        Chiudi
      </v-btn>
      <v-btn text color="default" :disabled="!formValid" @click="submitForm" :loading="loading">
        Registra
      </v-btn>
    </template>
  </FullScreenDialog>
</template>

<script>
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import CreditNoteFormTab from "./form/CreditNoteFormTab.vue";
import invoiceService from "@/services/invoices/invoices.service.js";
import invoiceForm from "@/services/invoices/invoice.form.js";
import Vue from "vue";

export default {
  name: "InvoicesCreditNoteNewForm",
  components: {
    FullScreenDialog,
    CreditNoteFormTab,
  },
  data: function () {
    return {
      tab: null,
      invoiceDialog: this.openDialog,
      invoiceCreditNoteTabs: [
        {
          key: 0,
          title: "Nota a Credito",
          name: "general",
          component: "CreditNoteFormTab",
        }
      ],
      formValid: false,
      invoice: undefined,
      originalUrl: window.location.pathname,
      invoiceBus: new Vue(),
      loading: false,
    };
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
    pathToGoBack: {
      type: String,
      default: "/payments/invoicesToPay/list",
    },
    tabName: {
      type: String,
      default: "general",
    },
  },
  mounted() {
    this.changeTabBasedOnProp(this.tabName);

    let self = this;
    invoiceForm.resetInvoice();
    invoiceForm.on("update", function (data) {
      self.invoice = data.invoice;
    });

    invoiceForm.on("valid-change", function (data) {
      self.formValid = data;
    })    
    this.invoiceBus.$on('update-amount', self.autoCalculateAmount)
  },
  methods: {
    
    changeTabBasedOnProp(tabName) {
      for (let i = 0; i < this.invoiceCreditNoteTabs.length; i++) {
        if (this.invoiceCreditNoteTabs[i].name == tabName) {
          this.tab = i;
          break;
        }
      }
    },
    submitForm() {
      let invoice = {};
      let customer = {};
      let supplier = {};
      let items = [];
      let services = [];
      let genericElements = [];
      if(!this.invoice.emittedAt) {
        this.$delegates.snackbar("Inserire la data di emissione");
        return;
      }
      if(!this.invoice.expirationDate) {
        this.$delegates.snackbar("Inserire la data di scadenza");
        return;
      }
      let expirationDate = new Date(this.invoice.expirationDate)
      expirationDate.setHours(0, 0, 0, 0)
      let emittedAt =  new Date(this.invoice.emittedAt)
      emittedAt.setHours(0, 0, 0, 0)
      if(expirationDate.getTime() < emittedAt.getTime()) {
        this.$delegates.snackbar("La data di scadenza non può essere antecedente a quella di emissione!");
        return;
      }
      if (!this.invoice.additionalAmount[0] || Number(this.invoice.additionalAmount[0]) <= 0) {
        this.$delegates.snackbar("Inserire almeno un elemento in fattura. Ammontare totale fattura uguale a 0. ");
        return;
      }
      if (
        !!this.invoice.invoiceElements &&
        Array.isArray(this.invoice.invoiceElements) &&
        this.invoice.invoiceElements.length > 0
      ) {
        this.invoice.invoiceElements.map((item) => {
          if (item.type === "items") items.push(item);
          else if (item.type === "services") services.push(item);
          else if (item.type === "genericElements") genericElements.push(item);
        });
      }

      if (!!this.invoice.customerId) {
        customer = { id: this.invoice.customerId };
        delete this.invoice.supplierId;
        delete this.invoice.invoiceElements;
        delete this.invoice.customerId;
        invoice = this.invoice;

        this.loading = true;
        invoiceService.createAndAddCustomer(invoice, customer).then((result) => {
          this.addInvoiceElements(result, items, services, genericElements).then(
            (value) => {
              this.loading = false;
              this.$router.push({ path: this.pathToGoBack });
            }
          );
        }).catch((err) => {console.log(err)});
      }
      else if (!!this.invoice.supplierId) {
        supplier = { id: this.invoice.supplierId };
        delete this.invoice.supplierId;
        delete this.invoice.invoiceElements;
        delete this.invoice.customerId;
        invoice = this.invoice;

        this.loading = true;
        invoiceService.createAndAddSupplier(invoice, supplier).then((result) => {
          this.addInvoiceElements(result, items, services, genericElements).then((value) => {
            this.loading = false;
            this.$router.push({ path: this.pathToGoBack });
          });
        }).catch((err) => {console.log(err)});
      }
    },
    addInvoiceElements(createdInvoice, items, services, genericElements) {
      return new Promise((resolve, reject) => {
        if (!!createdInvoice) {
          if (!!items && Array.isArray(items) && items.length > 0) {
            invoiceService.addItems(createdInvoice, items).then((result) => {}).catch((err) => {console.log(err)});
          }
          if (!!services && Array.isArray(services) && services.length > 0) {
            invoiceService.addServices(createdInvoice, services).then((result) => {}).catch((err) => {console.log(err)});
          }
           if (!!genericElements && Array.isArray(genericElements) && genericElements.length > 0) {
            invoiceService.addGenericElements(createdInvoice, genericElements).then((result) => {}).catch((err) => {console.log(err)});
          }
          resolve();
        } else reject(new Error("invoice not created correctly"));
      });
    },
    autoCalculateAmount(amount){
      this.invoiceBus.$emit('handle-update-amount', amount)
    }

  },
  watch: {
    openDialog(newVal) {
      this.invoiceDialog = newVal;
    },
    tab(newVal) {
      try {
        window.history.pushState(
          "Tab",
          "Tab",
          this.originalUrl + "?tabName=" + this.invoiceCreditNoteTabs[newVal].name
        );
      } catch (err) {
        console.log("try setting the tab name but something goes wrong");
      }
    },
  },
  computed: {},
};
</script>